import { z } from 'zod';

export const SignupFormStoreStats = z.object({
	storeID: z.number(),
	views: z.number(),
	signups: z.number(),
	firstView: z.date(),
	lastView: z.date(),
});

export const SignupFormViewStats = z.object({
	stores: z.array(SignupFormStoreStats),
	totalViews: z.number(),
	totalSignups: z.number(),
	firstView: z.date(),
	lastView: z.date(),
});
